<template>
  <div
    v-if="
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-commercial'
    "
  >
    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-2 mb-md-0"
          >
            <b-button
              v-b-modal.modal-new-offer-type
              variant="primary"
              class="mr-2"
            >
              <span class="text-nowrap font-medium-1">Ajouter</span>
            </b-button>

            <b-modal
              id="modal-new-offer-type"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Ajout d'une notification"
            >
              <validation-observer
                #default="{}"
                ref="addNewNotificationForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal(payload, 'modal-new-offer-type')"
                  @submit.prevent="applyAddNewNotificationAction"
                >
                  <!-- Email -->
                  <validation-provider
                    #default="{ errors }"
                    name="Titre"
                    rules="required"
                  >
                    <b-form-group
                      label="Titre"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        v-model="payload.title"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Contenu"
                    rules="required"
                  >
                    <b-form-group
                      label="Contenu"
                      label-for="content"
                    >
                      <b-form-textarea
                        id="content"
                        v-model="payload.content"
                        :state="errors.length > 0 ? false : null"
                        trim
                        placeholder="Contenu du notification"
                        rows="3"
                        max-rows="6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="image"
                  >
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <b-form-file
                        id="wildcard"
                        v-model="payload.image"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isAddNotificationLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isAddNotificationLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Enregistrer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

            <b-modal
              id="modal-update-offer-type"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Modification du notification"
            >
              <validation-observer
                #default="{}"
                ref="updateNotificationForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal(payload, 'modal-update-offer-type')"
                  @submit.prevent="applyUpdateNotificationAction"
                >
                  <!-- Email -->
                  <validation-provider
                    #default="{ errors }"
                    name="Titre"
                    rules="required"
                  >
                    <b-form-group
                      label="Titre"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        v-model="payload.title"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Contenu"
                    rules="required"
                  >
                    <b-form-group
                      label="Contenu"
                      label-for="content"
                    >
                      <b-form-textarea
                        id="content"
                        v-model="payload.content"
                        :state="errors.length > 0 ? false : null"
                        trim
                        placeholder="Contenu du notification"
                        rows="3"
                        max-rows="6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <div>
                    <b-media class="mb-2">
                      <template #aside>
                        <b-avatar
                          ref="previewEl"
                          :src="pushNotificationImage"
                          size="100px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <b-button
                          variant="primary"
                          @click="$refs.refInputEl.$el.click()"
                        >
                          <b-form-file
                            ref="refInputEl"
                            v-model="payload.image"
                            accept=".jpg, .png, .gif, .jpeg"
                            :hidden="true"
                            plain
                            @change="displayImage"
                          />
                          <span>Changer l'image</span>
                        </b-button>
                      </div>
                    </b-media>
                  </div>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isAddNotificationLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isUpdateNotificationLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Modifier</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-2 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="applyGetNotificationsListAction()"
            >
              <div v-if="isNotificationListLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span
                v-else
                class="text-nowrap font-medium-1"
              >Actualiser</span>
            </b-button>
          </b-col>
        </b-row>

        <vue-good-table
          class="mt-2"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :is-loading="isNotificationListLoading"
        >
          <template slot="emptystate">
            <div class="text-center text-info">
              <span> {{ isNotificationListLoading ? "" : "Liste vide" }}</span>
            </div>
          </template>

          <template slot="loadingContent">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle text-info" />
            </div>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field == 'image'"
              class="align-middle text-center"
            >
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    size="64"
                    :src="props.row.image"
                  >
                    <feather-icon
                      v-if="props.row.image == ''"
                      size="24"
                      icon="ShoppingBagIcon"
                    />
                  </b-avatar>
                </template>
              </b-media>
            </div>

            <div
              v-if="props.column.field == 'title'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.title }}
              </span>
            </div>

            <div
              v-if="props.column.field == 'content'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.content }}
              </span>
            </div>

            <div
              v-if="props.column.field == 'status'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.status === 0 ? "En attente d'envoie" : "Envoyé" }}
              </span>
            </div>

            <div
              v-if="props.column.field == 'date_sent'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.date_sent == null ? "---" : props.row.date_sent }}
              </span>
            </div>

            <div
              v-else-if="
                props.column.field == 'action' && props.row.content !== 'YLOMI'
              "
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="text-info align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="props.row.status === 0"
                    :id="`update${props.row.id}`"
                    v-b-modal.modal-update-offer-type
                    class="cursor-pointer itemActions"
                    @click="showUpdateNotificationModal(props.row)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Modifié</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :id="`delete${props.row.id}`"
                    class="cursor-pointer itemActions"
                    @click="applyDeleteNotificationAction(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger mr-1"
                      size="25"
                      variant="danger"
                    />
                    <span>Supprimer</span>
                  </b-dropdown-item>

                  
                  <b-dropdown-item
                    v-if="props.row.status == 0 && getUser.role.slug === 'super-administrateur'"
                    :id="`details${props.row.id}`"
                    class="cursor-pointer itemActions"
                    @click="sendNotification(props.row.id)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Envoyer</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </template>
        </vue-good-table>
        <div class="d-flex justify-content-between mt-2 flex-wrap">
          <b-button
            :disabled="metaData.prev_page_url == null"
            variant="outline-secondary"
            @click="applyLoadMorePointsFocauxAction(metaData.prev_page_url)"
          >
            <span class="text-white text-nowrap font-medium-2">Page précédente</span>
          </b-button>

          <div>
            <span class="text-white font-medium-2">{{ metaData.current_page }} sur
              {{ metaData.last_page }} pages</span>
          </div>

          <b-button
            variant="outline-secondary"
            :disabled="metaData.next_page_url == null"
            @click="applyLoadMorePointsFocauxAction(metaData.next_page_url)"
          >
            <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  BCard,
  BRow,
  BForm,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormInvalidFeedback,
  BButton,
  BTable,
  BMedia,
  BModal,
  BAvatar,
  // BLink,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BPagination,
  BTooltip,
  BSpinner,
  BFormFile,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  name: 'OfferTypesList',
  components: {
    // BTab,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BModal,
    // BTabs,
    BFormGroup,
    BFormTextarea,
    // BFormInvalidFeedback,
    BCard,
    // BAlert,
    // BLink,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTooltip,
    BSpinner,
    // vSelect,
    BFormFile,
  },
  data() {
    return {
      rows: [],
      avatarText,
      isAddNotificationLoading: false,
      isUpdateNotificationLoading: false,
      pushNotificationImage: null,
      pushNotificationId: null,
      payload: {
        title: '',
        content: '',
        image: '',
      },
      required,
      alphaNum,
      columns: [
        {
          label: 'Image',
          field: 'image',
         
        },
        {
          label: 'Titre',
          field: 'title',
        
        },
        {
          label: 'Contenu',
          field: 'content',
        
        },
        {
          label: 'Status',
          field: 'status',
          
        },
        {
          label: 'Envoyé le',
          field: 'date_sent',
        
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('users', ['getNotifications']),
    ...mapGetters(['isNotificationListLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getNotifications != null) {
        meta.prev_page_url = this.getNotifications.prev_page_url
        meta.next_page_url = this.getNotifications.next_page_url
        meta.current_page = this.getNotifications.current_page
        meta.last_page = this.getNotifications.last_page
        meta.current_page_url = `${this.getNotifications.path}?page=${this.getNotifications.current_page}`
      }
      return meta
    },
  },

  watch: {
    isNotificationListLoading(val) {
      if (val === false) {
        this.rows = this.getNotifications.data
      }
    },
  },

  created() {
    this.getNotifications.length !== 0
      ? (this.rows = this.getNotifications.data)
      : null
  },

  methods: {
    ...mapActions('users', [
      'loadMoreNotificationsAction',
      'addNewNotificationAction',
      'getNotificationsAction',
      'deleteNotificationAction',
      'updateNotificationAction',
      'sendNotificationAction',
    ]),

    displayImage() {
      // eslint-disable-next-line prefer-destructuring
      this.payload.image = this.$refs.refInputEl.$el.files[0]
      this.pushNotificationImage = URL.createObjectURL(this.payload.image)
    },

    applyGetNotificationsListAction() {
      this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', true)

      this.getNotificationsAction()
        .then(() => {
          console.log(this.metaData)
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
    },

    sendNotification(id) {
      this.$swal({
        title: 'Envoie de notification push',
        text: 'Êtes-vous sûr de vouloir envoyer cette notification ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => this.sendNotificationAction(id),
      })
        .then(async response => {
          if (response.isConfirmed) {
            this.applyLoadMoreNotificationsAction(
              this.metaData.current_page_url,
            )
            console.log('response', response.data)

            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyLoadMoreNotificationsAction(url) {
      this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', true)
      this.loadMoreNotificationsAction(url)
        .then(() => {
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
    },

    showUpdateNotificationModal(notification) {
      this.pushNotificationId = notification.id
      this.payload.title = notification.title
      this.payload.content = notification.content
      this.payload.image = notification.image
    },

    hideModal(data, modal) {
      (data.title = ''), (data.content = ''), this.$bvModal.hide(modal)
    },

    applyAddNewNotificationAction() {
      this.$refs.addNewNotificationForm.validate().then(success => {
        if (success) {
          this.isAddNotificationLoading = true
          this.addNewNotificationAction(this.payload)
            .then(response => {
              this.isAddNotificationLoading = false
              this.applyLoadMoreNotificationsAction(
                this.metaData.current_page_url,
              )
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-new-offer-type')
              this.payload = {
                title: '',
                content: '',
                image: '',
              }
            })
            .catch(error => {
              console.log(error)
              this.isAddNotificationLoading = false
            })
        }
      })
    },

    applyUpdateNotificationAction() {
      this.$refs.updateNotificationForm.validate().then(success => {
        if (success) {
          this.isUpdateNotificationLoading = true
          if (this.payload.image == null) {
            delete this.payload.image
          }
          this.updateNotificationAction({
            payload: this.payload,
            id: this.pushNotificationId,
          })
            .then(response => {
              this.isUpdateNotificationLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-update-offer-type')
              this.payload = {
                title: '',
                content: '',
                image: '',
              }
              this.applyLoadMoreNotificationsAction(
                this.metaData.current_page_url,
              )
            })
            .catch(error => {
              console.log(error)
              this.isUpdateNotificationLoading = false
            })
        }
      })
    },

    applyDeleteNotificationAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.deleteNotificationAction(id),
      })
        .then(async response => {
          console.log(response)
          if (response.value && response.value.status === 200) {
            this.applyLoadMoreNotificationsAction(
              this.metaData.current_page_url,
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
